import { OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import axios from 'axios';
import {
   Alert,
   API_ENDPOINTS,
   Button,
   ButtonTypes,
   Card,
   CardBody,
   CardData,
   customToast,
   DateFormats,
   formatDateFns,
   Modal,
   PayPalButton,
   replaceKeyWithValue,
   route,
   Sizes,
   StringHelpers,
   SubTitle,
   useLangContext,
   useQuery,
   Variants,
} from 'carrier-fe';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InstallationType } from '../types/installationType';
import { WarrantyOption, WarrantyResponse } from '../types/warranties';
import SendToHomeownerModal from './SendToHomeownerModal';
import WarrantiesEventLog from '../pages/warranties/eventLog';

function WarrantyDetails({
   installation,
   pageLang,
   onSuccess
}: {
   onSuccess: () => void;
   pageLang: any;
   installation: InstallationType;
}) {
   const { crud, fields } = useLangContext();

   const [selectedWarranty, setSelectedWarranty] = useState<WarrantyOption | null>(null);
   const [warrantyOptions, setWarrantyOptions] = useState<WarrantyOption[]>([]);
   const [applyingWarranty, setApplyingWarranty] = useState(false);
   const [showSendToHomeownerModal, setShowSendToHomeownerModal] = useState(false);
   const [paymentErrors, setPaymentErrors] = useState<
      [{ issue: string; description: string }] | null
   >(null);
   const {
      data: warrantyRes,
      isLoading,
      isFetched,
      refetch,
   } = useQuery<{ data: WarrantyResponse }>(
      route(API_ENDPOINTS.REGISTRATION.INSTALLATION.WARRANTY, {
         installationId: installation.id,
      })
   );

   useEffect(() => {
      if (warrantyRes?.data) {
      } else if (isFetched && !warrantyRes?.data) {
         fetchWarrantyOptions();
      }
   }, [isFetched, warrantyRes]);

   const fetchWarrantyOptions = async () => {
      try {
         const response = await axios.get(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.WARRANTY_OPTIONS, {
               installationId: installation.id,
            })
         );
         setWarrantyOptions(response.data.data);
      } catch (error: any) {
         console.log(error);
      }
   };

   const onWarrantySelect = (warranty: WarrantyOption) => {
      setSelectedWarranty(warranty);
   };

   const noWarrantyApplied = useMemo(() => {
      return !warrantyRes?.data;
   }, [warrantyRes?.data]);

   const isSelected = useCallback(
      (warranty: WarrantyOption) => {
         return selectedWarranty?.name === warranty.name;
      },
      [selectedWarranty]
   );

   const isFree = useCallback((warranty: WarrantyOption) => {
      return Number(warranty?.price) === 0;
   }, []);

   const handleCreateOrder = async () => {
      try {
         const response = await axios.post(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.START_WARRANTY_ORDER, {
               installationId: installation.id,
            }),
            {
               name: selectedWarranty?.name,
            }
         );

         return response.data.data.order_id;
      } catch (error: any) {
         console.log(error);
      }
   };
   const handleOnApprove = async (data: OnApproveData, actions: OnApproveActions) => {
      setPaymentErrors(null);
      const orderId = data.orderID;
      try {
         const response = await axios.post(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.CREATE_WARRANTY, {
               installationId: installation.id,
            }),
            {
               name: selectedWarranty?.name,
               order_id: orderId,
            }
         );
         setSelectedWarranty(null);
         customToast({
            title: response?.data?.message,
            variant: Variants.Success,
         });
         refetch();
         onSuccess()
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
         setPaymentErrors(error?.response?.data?.data);
      }
   };

   const handleApply = async () => {
      try {
         setApplyingWarranty(true);
         const response = await axios.post(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.CREATE_WARRANTY, {
               installationId: installation.id,
            }),
            {
               name: selectedWarranty?.name,
            }
         );
         setSelectedWarranty(null);
         customToast({
            title: response?.data?.message,
            variant: Variants.Success,
         });
         refetch();
         onSuccess()
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
      } finally {
         setApplyingWarranty(false);
      }
   };

   if (isLoading) return null;

   return (
      <Card>
         <CardBody>
            {noWarrantyApplied && installation?.permissions?.createWarranty ? (
               <div className="d-flex flex-column gap-3">
                  {/* when No warranty */}
                  <div className="d-flex justify-content-between">
                     <SubTitle
                        className="mb-3 w-100"
                        style={{ color: '#464C5E' }}
                        title={StringHelpers.title(
                           `${pageLang?.generic?.warranty || 'Warranty'} ${
                              crud?.sub_titles?.details
                           }`
                        )}
                     />

                  {/* TO DO- Disabled homeowner warranty option untill householder site is available */}
                     {/* <Button
                        label={StringHelpers.title(
                           pageLang?.page?.warranty_send_to_homeowner || 'Send to homeowner'
                        )}
                        size={Sizes.Medium}
                        onClick={() => setShowSendToHomeownerModal(true)}
                        variant={Variants.Dark}
                     />
                     <SendToHomeownerModal
                        pageLang={pageLang}
                        open={showSendToHomeownerModal}
                        onClose={() => setShowSendToHomeownerModal(false)}
                     /> */}

                  </div>
                  <Alert variant={Variants.Warning} className={'mt-2'}>
                     {pageLang?.page?.automatic_warranty_disclaimer}
                  </Alert>
                  {/* Warranties tile */}
                  <div className="row gap-2 align-content-end justify-content-center">
                     {warrantyOptions?.map((item, index) => (
                        <div
                           className={`col-12 p-0 col-md-6 col-lg-4 mb-4 text-center border d-flex flex-column ${
                              isSelected(item)
                                 ? 'border-2 border-primary'
                                 : 'border-secondary-subtle'
                           } rounded`}
                           key={`warranty-${index}`}
                           style={{ maxWidth: 300, height: 400 }}
                        >
                           {/* Header */}
                           <div
                              className="bg-dark d-flex justify-content-center align-items-center text-white"
                              style={{ height: 70, width: '100%' }}
                           >
                              <h5 className="fw-bold">{`${item.length} ${
                                 Number(item.length) === 1
                                    ? pageLang?.generic?.warranty_year
                                    : pageLang?.generic?.warranty_years
                              } ${pageLang?.generic?.warranty || 'Warranty'}`}</h5>
                           </div>

                           {/* Content Area */}
                           <div className="d-flex flex-column flex-grow-1 p-2 gap-4">
                              <div className="d-flex flex-column gap-2 flex-grow-1 justify-content-evenly">
                                 <DataItem layout="column" label="Name" value={item?.name} />
                                 <DataItem
                                    layout="column"
                                    label={fields?.price_incl_vat || 'Price (Inc VAT)'}
                                    value={
                                       isFree(item)
                                          ? StringHelpers.title('Free')
                                          : `£${item?.price}`
                                    }
                                 />
                                 <DataItem
                                    layout="column"
                                    label={StringHelpers.title(
                                       fields?.expiry_date || `Expiry Date`
                                    )}
                                    value={item.expiry_date}
                                 />
                              </div>

                              {/* Button Always at Bottom */}
                              <Button
                                 size={Sizes.Small}
                                 type={ButtonTypes.Solid}
                                 label={StringHelpers.title(
                                    isFree(item)
                                       ? replaceKeyWithValue(
                                            crud?.placeholders?.select,
                                            'model',
                                            ''
                                         )
                                       : pageLang?.generic?.purchase || 'Purchase'
                                 )}
                                 className="w-100 mt-auto"
                                 onClick={() => onWarrantySelect(item)}
                              />
                           </div>
                        </div>
                     ))}
                  </div>

                  {/* Purchase Warranty Modal */}
                  <Modal
                     open={selectedWarranty !== null}
                     onClose={() => {
                        setSelectedWarranty(null);
                        setPaymentErrors(null);
                     }}
                     confirmText={StringHelpers.title(
                        isFree(selectedWarranty as WarrantyOption)
                           ? applyingWarranty
                              ? crud?.buttons?.apply?.submitting
                              : crud?.buttons?.apply?.default
                           : pageLang?.generic?.purchase || 'Purchase'
                     )}
                     title={StringHelpers.title(
                        `${
                           isFree(selectedWarranty as WarrantyOption)
                              ? crud?.buttons?.apply?.default
                              : pageLang?.generic?.purchase
                        } ${selectedWarranty?.length} ${
                           Number(selectedWarranty?.length) === 1
                              ? pageLang?.generic?.warranty_year
                              : pageLang?.generic?.warranty_years
                        } ${pageLang?.generic?.warranty || 'Warranty'}`
                     )}
                     onConfirm={
                        isFree(selectedWarranty as WarrantyOption) ? handleApply : undefined
                     }
                     overriddenConfirmButton={
                        !isFree(selectedWarranty as WarrantyOption) ? (
                           <PayPalButton
                              onApprove={handleOnApprove}
                              createOrder={handleCreateOrder}
                           />
                        ) : undefined
                     }
                  >
                     <p>
                        {isFree(selectedWarranty as WarrantyOption)
                           ? pageLang?.page?.apply_warranty_text
                           : pageLang?.page?.purchase_warranty_text}
                     </p>

                     <div className="d-flex flex-column gap-2">
                        <DataItem
                           label={StringHelpers.title(fields?.name)}
                           value={selectedWarranty?.name}
                           layout="column"
                        />
                        <DataItem
                           label={StringHelpers.title(
                              fields?.warranty_start_date || 'Warranty Start Date'
                           )}
                           value={formatDateFns(installation?.completion_date, DateFormats.DATE)} // check start date
                           layout="column"
                        />

                        {!isFree(selectedWarranty as WarrantyOption) && (
                           <div className="d-flex flex-column gap-4">
                              <DataItem
                                 label={StringHelpers.title(
                                    fields?.price_incl_vat || 'Price (Inc VAT)'
                                 )}
                                 value={`£${selectedWarranty?.price}`}
                                 layout="column"
                              />
                           </div>
                        )}
                     </div>
                     {paymentErrors && (
                        <Alert className={'mt-4'} variant={Variants.Danger}>
                           {paymentErrors?.map((error, index) => (
                              <span>{error.description}</span>
                           ))}
                        </Alert>
                     )}
                  </Modal>
               </div>
            ) : (
               warrantyRes?.data && (
                  <div className="">
                     {/* When warranty applied */}
                     <SubTitle
                        className="mb-3 w-100"
                        style={{ color: '#464C5E' }}
                        title={StringHelpers.title(
                           `${pageLang?.generic?.warranty || 'Warranty'} ${
                              crud?.sub_titles?.details
                           }`
                        )}
                     />

                     <div className="row">
                        <CardData.Column>
                           <CardData.ColumnItem
                              title={StringHelpers.title(fields?.name || 'Name')}
                              value={warrantyRes?.data.warranty.name}
                           />
                           <CardData.ColumnItem
                              title={fields?.price_incl_vat || 'Price (Inc VAT)'}
                              value={
                                 !isFree(warrantyRes?.data.warranty as any)
                                    ? `£${warrantyRes?.data.warranty.price}`
                                    : crud?.sub_titles?.free || 'Free'
                              }
                           />
                           <CardData.ColumnItem
                              title={StringHelpers.title('Warranty Length (Years)')}
                              value={warrantyRes?.data.warranty.length}
                           />
                        </CardData.Column>
                        <CardData.Column>
                           <>
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    isFree(warrantyRes?.data.warranty as any)
                                       ? fields?.applied_by || 'Applied by'
                                       : fields?.purchased_by || 'Purchased by'
                                 )}
                                 value={warrantyRes?.data.applicant?.name}
                              />
                              <CardData.ColumnItem
                                 title={StringHelpers.title(
                                    isFree(warrantyRes?.data.warranty as any)
                                       ? fields?.applied_date || 'Applied Date'
                                       : fields?.purchase_date || 'Payment Date'
                                 )}
                                 value={formatDateFns(
                                    isFree(warrantyRes?.data.warranty as any)
                                       ? warrantyRes?.data.created_at
                                       : warrantyRes?.data.payment?.payment_date,
                                    DateFormats.DATE
                                 )}
                              />
                           </>

                           <CardData.ColumnItem
                              title={StringHelpers.title(
                                 fields?.certificate_sent_at || 'Warranty Certificate sent'
                              )}
                              value={formatDateFns(
                                 warrantyRes.data.certificate_sent_at,
                                 DateFormats.DATE
                              )}
                           />
                        </CardData.Column>
                        <CardData.Column>
                           <CardData.ColumnItem
                              title={StringHelpers.title(fields?.start_date || 'Start date')}
                              value={formatDateFns(warrantyRes.data.start_date, DateFormats.DATE)}
                           />
                           <CardData.ColumnItem
                              title={StringHelpers.title(fields?.expiry_date || 'Expiry date')}
                              value={formatDateFns(warrantyRes.data.end_date, DateFormats.DATE)}
                           />
                        </CardData.Column>
                     </div>

                     {warrantyRes?.data && <WarrantiesEventLog warrantyId={warrantyRes?.data?.id || ''}/>}
                  </div>
               )
            )}
            
         </CardBody>
      </Card>
   );
}

export default WarrantyDetails;

const DataItem = ({
   label,
   value = '-',
   layout = 'row',
}: {
   label: string;
   value: any;
   layout?: 'row' | 'column';
}) => {
   return (
      <div className={`d-flex flex-${layout} gap-1`}>
         <p className="fw-semibold m-0 p-0">{label}</p>
         {layout === 'row' && ':'}
         <p className="text-muted m-0 p-0">{value}</p>
      </div>
   );
};
