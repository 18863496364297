import {
   API_ENDPOINTS,
   DataTable,
   formatDate,
   FormInput,
   Icon,
   Icons,
   ImageType,
   PageLayout,
   route,
   useLangContext,
   replaceKeyWithValue,
   StringHelpers,
   useAuthorisationContext,
   useUserContext,
   DateFormats,
} from 'carrier-fe';
import { useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { WarrantyType } from '../../types/WarrantyType';
import { format } from 'date-fns';

function Warranties() {
   const [refreshOnChange, setRefreshOnChange] = useState(
      new Date().toISOString()
   );
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const { crud, fields, models } = useLangContext();
   const { hasPolicyAccess } = useAuthorisationContext();
   const { user } = useUserContext();

   const title = useMemo(
      () =>
         StringHelpers.title(
            replaceKeyWithValue(
               crud?.pages?.index.title || ':model List ',
               'model',
               crud?.models?.warranty || 'Warranty'
            )
         ),
      [crud, models]
   );

   const handleOptionSelect = (
      action: string | ImageType | null,
      data: WarrantyType
   ) => {
      const { viessmann_installation_id = '' } = data;

      const actions: { [key: string]: () => void } = {
         view: () => navigate(`/installation/${viessmann_installation_id}`),
      };

      if (typeof action === 'string' && actions[action]) {
         actions[action]();
      }
   };


   const colMapping = (data: WarrantyType, user: any) => {

      const cols: any = [
         {
            id: 'company_name',
            label: StringHelpers.title(fields?.name || 'Name'),
            value: data?.company_name || '-',
            sortable: true,
         },
         {
            id: 'appliance',
            label: StringHelpers.title(fields?.appliance_name || 'appliance name'),
            value: data?.appliance || '-',
            sortable: true,
         },
         {
            id: 'engineer_name',
            label: StringHelpers.title(fields?.engineer || 'Engineer'),
            value: data?.engineer_name || '-',
            sortable: true,
         },
         {
            id: 'customer_name',
            label: StringHelpers.title(crud?.models?.customer || 'Customer'),
            value: data?.customer_name || '-',
            sortable: true,
         },
         {
            id: 'start_date',
            label: StringHelpers.title(fields?.start_date || 'Start date'),
            value: format(new Date(data.start_date), DateFormats.DATE) || '-',
            sortable: true,
         },
         {
            id: 'end_date',
            label: StringHelpers.title(fields?.end_date || 'End date'),
            value: format(new Date(data.end_date), DateFormats.DATE) || '-',
            sortable: true,
         },
         {
            id: 'price',
            label: `${StringHelpers.title(fields?.price || 'price')} £`,
            value: Number(data?.price).toFixed(2) || '',
            sortable: true,
         },
         {
            id: 'actions',
            label: StringHelpers.title(fields?.actions || 'Actions'),
            value: data.permissions.view ? (
               <FormInput
                  type="select"
                  label={StringHelpers.title(fields?.actions || 'Action')}
                  placeholder={StringHelpers.title(fields?.select || 'Select')}
                  name="actions"
                  value=""
                  onChange={(value: any) => {
                     handleOptionSelect(value, data);
                  }}
                  options={[
                     {
                        label: StringHelpers.title(
                           crud?.view || 'View'
                        ),
                        value: 'view',
                     },
                  ]}
                  noMargin
               />
            ) : null,
         },
      ];

      return cols;
   };


   return (
      <PageLayout title={title || 'Warranty List'}>
         <DataTable<WarrantyType>
            routeEndpoint={route(API_ENDPOINTS.REGISTRATION.WARRANTY.INDEX)}
            colMapping={(data) => colMapping(data, user)}
            incrementPaginationButtons
            refreshOnChange={refreshOnChange}
         />
      </PageLayout>
   );
}

export default Warranties;
