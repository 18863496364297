import {
	PageLayout,
	Card,
	CardBody,
	Button,
	CardPlaceholder,
	Permissions,
	Icon,
	Icons,
	useAuthorisationContext,
	useLangContext,
	StringHelpers,
	checkTheme,
	Themes,
	Variants,
} from 'carrier-fe';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SYSTEM_TYPES } from '../../util/Constants';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannElectric from 'carrier-fe/src/images/viessmann_electric.png'
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';

function Dashboard() {
	const { hasPermissionAccess } = useAuthorisationContext();
	const { getLangPage } = useLangContext();

	const [lang, setLang] = useState<any>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('registration/dashboard')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title ?? 'Dashboard'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	};

	const renderGasBoilerTile = () => {
		// TODO - Hide Tile If GasBoilers Not Enabled For Company?
		if (!checkTheme(Themes.Viessmann) || !hasPermissionAccess(Permissions.VIESSMANN_INSTALLATION_STORE)) {
			return null;
		}

		let content = getTileById('registration.gas.index');

		return (
			<Card noMargin>
				<CardBody>
					<img src={ViessmannGas} alt="Gas Icon" className="w-3rem" />
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Register a Gas Boiler'}
					</h4>

					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/installation/create?type=${SYSTEM_TYPES.GAS_BOILER}`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}

	const renderHeatPumpTile = () => {
		// TODO - Hide Tile If Heat Pumps Not Enabled For Company?
		if ((!checkTheme(Themes.Viessmann) || !hasPermissionAccess(Permissions.VIESSMANN_INSTALLATION_STORE))) {
			return null;
		}

		let content = getTileById('registration.heat.index');

		return (
			<Card noMargin>
				<CardBody>
					<img src={ViessmannHeatpumps} alt="Heat icon" className="w-3rem" />
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Register a Heat Pump'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/installation/create?type=${SYSTEM_TYPES.HEAT_PUMPS}`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}
	const renderSolarProductTile = () => {
		// TODO - Hide Tile If Solar Not Enabled For Company?
		if ((!checkTheme(Themes.Viessmann) || !hasPermissionAccess(Permissions.VIESSMANN_INSTALLATION_STORE))) {
			return null;
		}

		let content = getTileById('registration.solar.index');

		return (
			<Card noMargin>
				<CardBody>
					<img src={ViessmannSolar} alt="Solar Icon" className="w-3rem" />
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Register a Solar product'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/installation/create?type=${SYSTEM_TYPES.SOLAR}`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}

	const renderElectricProductTile = () => {
		// TODO - Hide Tile If Other Not Enabled For Company?
		if ((!checkTheme(Themes.Viessmann) || !hasPermissionAccess(Permissions.VIESSMANN_INSTALLATION_STORE))) {
			return null;
		}

		let content = getTileById('registration.electric.index');

		return (
			<Card noMargin>
				<CardBody>
					<img src={ViessmannElectric} alt=" Icon" className="w-3rem" />
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Register an Electric product'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/installation/create?type=${SYSTEM_TYPES.ELECTRIC_BOILER}`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}

	const renderInstallationsTile = () => {
		if (!hasPermissionAccess(Permissions.VIESSMANN_INSTALLATION_INDEX) || !checkTheme(Themes.Viessmann)) {
			return null;
		}


		let content = getTileById('registration.installations.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.APARTMENT}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'View my previous installations'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate('/installation')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}
	const renderWarrantiesTile = () => {
		if (!hasPermissionAccess(Permissions.TOSHIBA_WARRANTY_INDEX) && !hasPermissionAccess(Permissions.VIESSMANN_WARRANTY_INDEX)) {
			return null;
		}

		let content = getTileById('registration.warranty.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Warranties'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/warranties`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}
	const renderClaimsTile = () => {
		if ((!checkTheme(Themes.Toshiba) ||
			!hasPermissionAccess(Permissions.TOSHIBA_CLAIM_INDEX))
		)
			return null;

		let content = getTileById('registration.claim.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Claims'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}
	const renderCustomersTile = () => {
		if (!checkTheme(Themes.Toshiba) || !hasPermissionAccess(Permissions.CUSTOMER_INDEX)) {
			return null;
		}

		let content = getTileById('registration.customer.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#ff3e17' }}
					/>
					<h4 className="card-title mb-4">
						{!!content && !!content.title
							? content.title
							: 'Customers'}
					</h4>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						onClick={() => navigate(`/customers`)}
						className="d-inline-flex"
						variant={Variants.Info}
					/>
				</CardBody>
			</Card>
		);
	}

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderGasBoilerTile()}
						{renderHeatPumpTile()}
						{renderSolarProductTile()}
						{renderElectricProductTile()}
						{renderInstallationsTile()}
						{renderWarrantiesTile()}
						{renderClaimsTile()}
						{renderCustomersTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
