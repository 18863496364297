import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { StepProps, StepsLangPage } from '../../types/Journey';
import { InstallationFormData } from '../../pages/installation/journey';
import {
	API_ENDPOINTS,
	CheckboxInput,
	customToast,
	FormInput,
	route,
	SelectOption,
	StringHelpers,
	useLangContext,
	Variants,
	Stepper,
	useQueries,
	ImageType,
	SubTitle,
	SummaryLineItems,
	DateFormats,
	formatDateFns,
} from 'carrier-fe';
import { QUERY_KEYS, SYSTEM_TYPES, TEN_MINUTES } from '../../util/Constants';
import axios from 'axios';
import { InstallationResponse } from '../../types/Installation';
import { useParams, useSearchParams } from 'react-router-dom';

const cacheConfig = {
	enabled: true,
	config: {
		expires: TEN_MINUTES,
	},
};

function Step7({ form, stepLang, pageLang }: StepProps) {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const installationId =
		params?.installationId || searchParams?.get('installationId') || '';
	const { crud, fields } = useLangContext();
	const [summaryData, setSummaryData] = useState(form?.store);
	const [installation, setInstallation] = useState<InstallationResponse>();
	const [isFetching, setIsFetching] = useState(false);
	const [installers, setInstallers] = useState<SelectOption[]>([]);
	const [workCats, setWorkCats] = useState<SelectOption[]>([]);

	// Getting dropdowns to show labels on fields instead of ids
	const { data, error, isLoading } = useQueries([
		{
			key: QUERY_KEYS.INSTALLER_COMPANIES,
			config: {
				method: 'GET',
				url: route(
					`${API_ENDPOINTS.GENERIC.SELECT.COMPANIES}?types[]=installer`
				),
			},
		},
		{
			key: QUERY_KEYS.CUSTOMERS,
			config: {
				method: 'GET',
				url: route(
					`${API_ENDPOINTS.GENERIC.SELECT.CUSTOMERS}?company_id=${form?.store?.installer_company_id}`
				),
			},
		},
		{
			key: QUERY_KEYS.MERCHANTS,
			config: {
				method: 'GET',
				url: route(API_ENDPOINTS.GENERIC.SELECT.MERCHANTS),
			},
			cache: cacheConfig,
		},
	]);

   const fetchInstallation = async () => {
      setIsFetching(true);
      try {
         const response = await axios.get<{ data: InstallationResponse }>(
            route(`${API_ENDPOINTS.REGISTRATION.INSTALLATION.VIEW}`, {
               installationId,
            })
         );
         setInstallation(response?.data?.data);
         await fetchInstallers(response?.data?.data);
         if(response?.data?.data?.system_type === SYSTEM_TYPES.GAS_BOILER){
            await fetchSubWorkCats(response?.data?.data);
         }
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
      } finally {
         setIsFetching(false);
      }
   };

	useLayoutEffect(() => {
		fetchInstallation();
	}, [installationId]);

	const fetchInstallers = async (installation: InstallationResponse) => {
		try {
			const response = await axios.get(
				route(API_ENDPOINTS.GENERIC.SELECT.INSTALLERS, {
					companyId: installation?.installer_company_id,
				})
			);

			setInstallers(response?.data?.data);
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		}
	};

	const fetchSubWorkCats = async (installation: InstallationResponse) => {
		try {
			const response = await axios.get<{ data: SelectOption[] }>(
				route(
					`${
						API_ENDPOINTS.GENERIC.GAS_SAFE.WORK_CATEGORIES
					}?reportable=${1}&gas_safe_licence_card_number=${
						installation?.installer_individual?.gas_safe_licence_card_number
					}&needs_appliance=${0}&completion_date=${
						installation?.completion_date
					}`
				)
			);
			setWorkCats(response?.data?.data);
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message,
				variant: Variants.Danger,
			});
		}
	};

	const findOption = (
		value: string,
		options: SelectOption[]
	): SelectOption => {
		try {
			return (
				options?.find((option) => {
					return option.value === value;
				}) ?? { label: '-', value }
			);
		} catch (error) {
			return { label: '-', value };
		}
	};

	useEffect(() => {
		if (error) {
			Object.values(error).map((e) =>
				customToast({
					title: (e?.response?.data as any)?.message,
					variant: Variants.Danger,
				})
			);
		}
		if (data && installation) {
			const currentSummaryData = { ...form.store, ...installation };

			const updateSummaryData = () => {
				try {
					if (workCats) {
						currentSummaryData['appliances'] =
							currentSummaryData?.appliances?.map(
								(appliance) => ({
									...appliance,
									sub_work_cats:
										appliance?.sub_work_cats?.map(
											(cat) =>
												findOption(cat, workCats)?.label
										),
								})
							);
					}

					if (data?.installer_companies) {
						currentSummaryData['installer_company_id'] = findOption(
							currentSummaryData['installer_company_id'],
							data?.installer_companies?.data
						)?.label;
					}
					if (installers) {
						currentSummaryData['installer_user_id'] = findOption(
							currentSummaryData['installer_user_id'],
							installers
						)?.label;
					}
					if (data?.merchants) {
						currentSummaryData['appliances'] =
							currentSummaryData?.appliances?.map(
								(appliance) => ({
									...appliance,
									merchant_id: findOption(
										appliance.merchant_id,
										data?.merchants?.data
									)?.label,
								})
							);
					}

					setSummaryData(currentSummaryData as any);
				} catch (error) {
					customToast({
						title: 'Something went wrong',
						variant: Variants.Danger,
					});
				}
			};

			updateSummaryData();
		}
	}, [data, error, installation, installers, workCats]);

	const summaryConf = useMemo(() => {
		return summaryConfig(summaryData, fields, crud, pageLang);
	}, [crud, fields, summaryData, pageLang]);

	return (
		<Stepper.Step
			loading={isLoading || isFetching}
			title={stepLang?.title}
			description={stepLang?.description}
		>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex flex-column gap-3">
					{summaryConf?.map((item, index) => (
						<SummaryLineItems
							maxPerView={3}
							key={index}
							title={item.title}
							items={item.items}
						/>
					))}
				</div>
				<div>
					<SubTitle
						title={StringHelpers.title(
							crud?.sub_titles?.confirmation || 'Confirmation'
						)}
						className="mb-3 w-100"
						style={{ color: '#464C5E' }}
					/>
					<FormInput
						name="confirm"
						label={StringHelpers.sentence(
							fields?.confirmed || fallbackFieldLabels.confirmed
						)}
						type="select"
						required
						value={form.store['confirmed']}
						onChange={(
							value:
								| string
								| boolean
								| string[]
								| ImageType
								| null
						) =>
							form?.setValues({
								confirmed:
									typeof value === 'string'
										? JSON.parse(value)
										: value,
							})
						}
						options={crud?.options?.boolean.map(
							(option: SelectOption) => ({
								...option,
								value:
									Number(option.value) === 1 ? true : false,
							})
						)}
						errorMessages={form.errors?.['confirmed']}
					/>
				</div>
			</div>
		</Stepper.Step>
	);
}

export default Step7;

type SummaryItems = React.ComponentProps<typeof SummaryLineItems>['items'];
/**
 * BEHOLD THE SUMMONER
 * - Builds journey summary
 * - handles conditional summary items e.g. ...(data['subcontractor_exists']
 */
const summaryConfig = (
	data: InstallationFormData,
	langFields: any,
	crud: any,
	pageLang?: StepsLangPage
): { title: string; items: SummaryItems }[] => {
	const yesNoOptions = crud?.options?.boolean.reduce(
		(acc: any, option: SelectOption) => {
			acc[option.value] = option.label;
			return acc;
		},
		{}
	);

	return [
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 01: ${
					pageLang?.page?.steps[0].title || 'Installer'
				}`
			),
			items: [
				{
					label: StringHelpers.sentence(
						langFields?.installer_company_id ||
							fallbackFieldLabels.installer_company_id
					),
					value: StringHelpers.sentence(
						data['installer_company_id'] || '-'
					),
				},
				{
					label: StringHelpers.sentence(
						langFields?.installer_user_id ||
							fallbackFieldLabels.installer_user_id
					),
					value: StringHelpers.sentence(
						data['installer_user_id'] || '-'
					),
				},
				{
					label: StringHelpers.sentence(
						langFields?.gassafe_certificate_no ||
							fallbackFieldLabels.gassafe_certificate_no
					),
					value: StringHelpers.sentence(
						data['gassafe_certificate_no'] || '-'
					),
				},
			],
		},
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 02: ${
					pageLang?.page?.steps[1].title || 'System Type'
				}`
			),
			items: [
				{
					label: StringHelpers.sentence(
						langFields?.system_type ||
							fallbackFieldLabels.system_type
					),
					value: StringHelpers.sentence(
						StringHelpers.replace(
							data['system_type'] || '-',
							'_',
							' '
						)
					),
				},
			],
		},
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 03: ${
					pageLang?.page?.steps[2].title || 'Installation Overview'
				}`
			),
			items: [
				{
					label: StringHelpers.sentence(
						langFields?.completion_date ||
							fallbackFieldLabels.completion_date
					),
					value: data['completion_date']
						? formatDateFns(
								data['completion_date'],
								DateFormats.DATE
						  )
						: '-',
				},
				{
					label: StringHelpers.sentence(
						langFields?.subcontractor_exists ||
							fallbackFieldLabels.subcontractor_exists
					),
					value: StringHelpers.sentence(
						yesNoOptions[data['subcontractor_exists'] ? 1 : 0]
					),
				},
				...(data['subcontractor_exists']
					? [
							{
								label: StringHelpers.sentence(
									langFields?.subcontractor_company_name ||
										fallbackFieldLabels.subcontractor_company_name
								),
								value: StringHelpers.sentence(
									data['subcontractor_company_name'] || '-'
								),
							},
							...(data?.system_type === SYSTEM_TYPES.GAS_BOILER
								? [
										{
											label: StringHelpers.sentence(
												langFields?.subcontractor_company_gas_safe_number ||
													fallbackFieldLabels.subcontractor_company_gas_safe_number
											),
											value: StringHelpers.sentence(
												data[
													'subcontractor_company_gas_safe_number'
												] || '-'
											),
										},
										{
											label: StringHelpers.sentence(
												langFields?.subcontractor_company_gas_safe_number_confirmed ||
													fallbackFieldLabels.subcontractor_company_gas_safe_number_confirmed
											),
											value: StringHelpers.sentence(
												yesNoOptions[
													data[
														'subcontractor_company_gas_safe_number_confirmed'
													]
														? 1
														: 0
												]
											),
										},
								  ]
								: []),
							{
								label: StringHelpers.sentence(
									langFields?.subcontractor_engineer_gas_safe_licence_card_number ||
										fallbackFieldLabels.subcontractor_engineer_gas_safe_licence_card_number
								),
								value: StringHelpers.sentence(
									data[
										'subcontractor_engineer_gas_safe_licence_card_number'
									] || '-'
								),
							},
							{
								label: StringHelpers.sentence(
									langFields?.subcontractor_engineer_gas_safe_licence_card_number_confirmed ||
										fallbackFieldLabels.subcontractor_engineer_gas_safe_licence_card_number_confirmed
								),
								value: StringHelpers.sentence(
									yesNoOptions[
										data[
											'subcontractor_engineer_gas_safe_licence_card_number_confirmed'
										]
											? 1
											: 0
									]
								),
							},
							{
								label: StringHelpers.sentence(
									langFields?.subcontractor_engineer_first_name ||
										fallbackFieldLabels.subcontractor_engineer_first_name
								),
								value: StringHelpers.sentence(
									data['subcontractor_engineer_first_name'] ||
										'-'
								),
							},
							{
								label: StringHelpers.sentence(
									langFields?.subcontractor_engineer_last_name ||
										fallbackFieldLabels.subcontractor_engineer_last_name
								),
								value: StringHelpers.sentence(
									data['subcontractor_engineer_last_name'] ||
										'-'
								),
							},
					  ]
					: []),
			],
		},
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 04: ${
					pageLang?.page?.steps[3].title ||
					'Appliance Installation Address'
				}`
			),
			items: [
				{
					label: StringHelpers.title(
						`${crud?.models?.installation} ${crud?.sub_titles?.address}`
					),
					value: [
						{
							label: StringHelpers.title(
								langFields?.address_line_1 ||
									fallbackFieldLabels.address_line_1
							),
							value: data?.address_line_1 || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.address_line_2 ||
									fallbackFieldLabels.address_line_2
							),
							value: data?.address_line_2 || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.address_line_3 ||
									fallbackFieldLabels.address_line_3
							),
							value: data?.address_line_3 || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.town_city ||
									fallbackFieldLabels.town_city
							),
							value: data?.town_city || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.county || fallbackFieldLabels.county
							),
							value: data?.state_county || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.postcode ||
									fallbackFieldLabels.postcode
							),
							value: data?.postcode_zipcode || '-',
						},
						{
							label: StringHelpers.title(
								langFields?.country_code_iso_3 ||
									fallbackFieldLabels.country_code_iso_3
							),
							value: data?.sub_country_name || '-',
						},
					],
				},
				{
					label: StringHelpers.sentence(
						langFields?.customer_property_type_for_gas_safe ||
							fallbackFieldLabels.customer_property_type_for_gas_safe
					),
					value: StringHelpers.sentence(
						data['customer_property_type_for_gas_safe'] || '-'
					),
				},
				{
					label: StringHelpers.sentence(
						langFields?.job_reference ||
							fallbackFieldLabels.job_reference
					),
					value: StringHelpers.sentence(data['job_reference'] || '-'),
				},
			],
		},
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 05: ${
					pageLang?.page?.steps[4].title ||
					'Homeowner Contact Details'
				}`
			),
			items: [
				{
					label: StringHelpers.title(
						langFields?.customer_title ||
							fallbackFieldLabels.customer_title
					),
					value: data?.customer_title || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.type || fallbackFieldLabels.type
					),
					value: data?.customer_contact_type_for_gas_safe || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.first_name || fallbackFieldLabels.first_name
					),
					value: data?.first_name || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.last_name || fallbackFieldLabels.last_name
					),
					value: data?.last_name || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.email || fallbackFieldLabels.email
					),
					value: data?.email || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.mobile_phone_number ||
							fallbackFieldLabels.mobile_phone_number
					),
					value: data?.mobile_phone_number || '-',
				},
				{
					label: StringHelpers.title(
						langFields?.landline_phone_number ||
							fallbackFieldLabels.landline_phone_number
					),
					value: data?.landline_phone_number || '-',
				},
			],
		},
		{
			title: StringHelpers.title(
				`${crud?.sub_titles?.step || 'Step'} 06: ${
					pageLang?.page?.steps[5].title || 'Appliances'
				}`
			),
			items: data?.appliances?.map((item, index) => ({
				label: StringHelpers.sentence(
					langFields?.appliance || `Appliance ${index + 1}`
				),
				value: [
					{
						label: StringHelpers.sentence(
							langFields?.serial_number ||
								fallbackFieldLabels.serial_number
						),
						value: StringHelpers.sentence(
							item?.serial_number || '-'
						),
					},
					...(data?.system_type === SYSTEM_TYPES.GAS_BOILER
						? [
								{
									label: StringHelpers.sentence(
										langFields?.gas_safe_product_location ||
											fallbackFieldLabels.product_location
									),
									value: StringHelpers.sentence(
										item?.gas_safe_product_location || '-'
									),
								},
								{
									label: StringHelpers.sentence(
										langFields?.gas_safe_work_category ||
											fallbackFieldLabels.work_category
									),
									value: item?.gas_safe_work_category || '-',
								},
								{
									label: StringHelpers.sentence(
										langFields?.notify_building_regulations ||
											fallbackFieldLabels.notify_building_regulations
									),
									value: StringHelpers.title(
										yesNoOptions[
											item?.notify_building_regulations
												? 1
												: 0
										]
									),
								},
								item?.notify_building_regulations
									? {
											label: StringHelpers.sentence(
												langFields?.sub_work_cats ||
													fallbackFieldLabels.sub_work_cats
											),
											value:
												item?.sub_work_cats?.join(
													', '
												) || '-',
									  }
									: null,
						  ].filter(Boolean)
						: []),
					{
						label: StringHelpers.sentence(
							langFields?.merchant_id ||
								fallbackFieldLabels.merchant_id
						),
						value: StringHelpers.sentence(item?.merchant_id || '-'),
					},
					item?.merchant_id?.toLowerCase() === 'other'
						? {
								label: StringHelpers.sentence(
									langFields?.other_merchant_name ||
										fallbackFieldLabels.other_merchant_name
								),
								value: StringHelpers.sentence(
									item?.other_merchant_name || '-'
								),
						  }
						: null,
					{
						label: StringHelpers.sentence(
							langFields?.installer_will_service ||
								fallbackFieldLabels.installer_will_service
						),
						value: StringHelpers.sentence(
							yesNoOptions[item?.installer_will_service ? 1 : 0]
						),
					},
					!item?.installer_will_service
						? {
								label: StringHelpers.sentence(
									langFields?.servicing_details ||
										fallbackFieldLabels.servicing_details
								),
								value: StringHelpers.sentence(
									item?.servicing_details || '-'
								),
						  }
						: null,
					!item?.installer_will_service
						? {
								label: StringHelpers.sentence(
									langFields?.viessmann_will_contact_customer_about_servicing ||
										fallbackFieldLabels.viessmann_will_contact_customer_about_servicing
								),
								value: StringHelpers.sentence(
									yesNoOptions[
										item?.viessmann_will_contact_customer_about_servicing
											? 1
											: 0
									]
								),
						  }
						: null,
				].filter(Boolean) as any,
			})),
		},
	];
};

const fallbackFieldLabels = {
	installer_company_id: 'installation company',
	installer_user_id: 'installation engineer',
	gassafe_certificate_no: 'gassafe certificate no',
	system_type: 'system type',
	completion_date: 'work completion date',
	subcontractor_exists: 'work was subcontracted',
	subcontractor_company_name: 'company name',
	subcontractor_company_gas_safe_number: 'company GasSafe number',
	subcontractor_company_gas_safe_number_confirmed:
		"Can you confirm that the company's GasSafe details are correct?",
	subcontractor_engineer_gas_safe_licence_card_number:
		'engineer GasSafe licence card number',
	subcontractor_engineer_gas_safe_licence_card_number_confirmed:
		"Can you confirm that the engineer's GasSafe details are correct?",
	subcontractor_engineer_first_name: 'engineer first name',
	subcontractor_engineer_last_name: 'engineer last name',
	address_line_1: 'address line 1',
	address_line_2: 'address line 2',
	address_line_3: 'address line 3',
	town_city: 'town city',
	county: 'county',
	postcode: 'postcode',
	country_code_iso_3: 'country',
	customer_company_id: 'customer',
	customer_title: 'title',
	customer_contact_type_for_gas_safe: 'customer type',
	location_new_build: 'location new build',
	local_authority_code: 'local authority code',
	customer_property_type_for_gas_safe: 'property type',
	job_reference: 'job reference',
	customer_email_override_exists: 'override customer email',
	customer_email_override: 'customer email',
	appliances: 'appliance details',
	merchant_id: 'merchant',
	serial_number: 'serial number',
	product_location: 'product location',
	other_merchant_name: 'merchant other',
	work_category: 'work category',
	installer_will_service:
		'Do you intend to service and maintain this product?',
	servicing_details: 'please provide details',
	viessmann_will_contact_customer_about_servicing:
		'Do you want Viessmann to contact you about servicing the product?',
	confirmed: 'Do you confirm that the details provided above are correct?',
	first_name: 'first name',
	last_name: 'last name',
	mobile_phone_number: 'mobile phone number',
	landline_phone_number: 'landline phone number',
	type: 'type',
	email: 'email',
	sub_work_cats: 'Extra work categories',
	notify_building_regulations: 'notify building regulations',
};
