import {
   API_ENDPOINTS,
   BottomAnchoredControls,
   Button,
   ButtonTypes,
   Card,
   CardBody,
   customToast,
   Icon,
   Modal,
   PageLayout,
   replaceKeyWithValue,
   route,
   Stepper,
   StringHelpers,
   useDisabledContext,
   useForm,
   useLangContext,
   useNavigationGuard,
   useScrollToError,
   useStepper,
   Variants,
   WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Step1 from '../../../components/installation-journey/Step1';
import Step2 from '../../../components/installation-journey/Step2';
import Step3 from '../../../components/installation-journey/Step3';
import Step4 from '../../../components/installation-journey/Step4';
import Step5 from '../../../components/installation-journey/Step5';
import Step6, { defaultApplianceItem } from '../../../components/installation-journey/Step6';
import Step7 from '../../../components/installation-journey/Step7';
import axios from 'axios';
import { ApplianceDetails, InstallationResponse } from '../../../types/Installation';
import { configurePayload, loadPageLang } from '../../../util/journey-utils';
import { DEFAULT_NAVIGATION_GUARD, STEPS, TOTAL_STEPS } from '../../../util/Constants';
import { StepsLangPage } from '../../../types/Journey';

const defaultData = {
   // step 1
   installer_company_id: '',
   installer_user_id: '',
   installer_individual: '',
   gassafe_certificate_no: '',
   gas_safe_licence_card_number: '',
   // step 2
   system_type: '',
   // step 3
   completion_date: '',
   subcontractor_exists: false,
   subcontractor_address_id: '',
   subcontractor_address_line_1: '',
   subcontractor_address_line_2: '',
   subcontractor_address_line_3: '',
   subcontractor_sub_country_name: '',
   subcontractor_local_authority_code: '',
   subcontractor_town_city: '',
   subcontractor_state_county: '',
   subcontractor_postcode_zipcode: '',
   subcontractor_country_code_iso_3: '',
   subcontractor_company_name: '',
   subcontractor_company_gas_safe_number_confirmed: false,
   subcontractor_company_gas_safe_number: '',
   subcontractor_engineer_first_name: '',
   subcontractor_engineer_last_name: '',
   subcontractor_engineer_gas_safe_licence_card_number_confirmed: false,
   subcontractor_engineer_gas_safe_licence_card_number: '',
   // step 4
   address_id: '',
   address_line_1: '',
   address_line_2: '',
   address_line_3: '',
   town_city: '',
   state_county: '',
   postcode_zipcode: '',
   local_authority_code: '',
   sub_country_name: '',
   country_code_iso_3: '',
   location_new_build: false,
   job_reference: '',
   customer_property_type_for_gas_safe: '',
   // step 5
   customer_contact_type_for_gas_safe: '',
   customer_title: '',
   first_name: '',
   last_name: '',
   email: '',
   landline_phone_prefix: '',
   landline_phone_number: '',
   mobile_phone_prefix: '',
   mobile_phone_number: '',
   // step 6
   appliances: [
      {
         merchant_id: '',
         appliance_details: {
            appliance_type: '',
            full_name: '',
            installation_type: '',
            model: '',
            version: '',
         } as ApplianceDetails,
         serial_number: '',
         other_merchant_name: '',
         installer_will_service: 1,
         servicing_details: '',
         gas_safe_product_location: '',
         gas_safe_work_category_id: '',
         sub_work_cats: [],
         gas_safe_work_category: '',
         viessmann_will_contact_customer_about_servicing: true,
         notify_building_regulations: false,
      },
   ],
   // step 6
   confirmed: false,
};

export type InstallationFormData = typeof defaultData;

function Journey() {
   const { installationId } = useParams();
   const { crud, getLangPage } = useLangContext();

   const [searchParams] = useSearchParams();
   const { disabled, setDisabled } = useDisabledContext();
   const [loading, setLoading] = useState(false);
   const [helpModalOpen, setHelpModalOpen] = useState(false);
   const [pageLang, setPageLang] = useState<StepsLangPage>();
   const { activeStep, nextStep, prevStep, isFirstStep, isLastStep } = useStepper({
      totalSteps: TOTAL_STEPS,
   });

   useNavigationGuard({
      message: crud?.modals?.alert?.description?.at(0) || DEFAULT_NAVIGATION_GUARD,
      isEnabled: !isLastStep,
   });

   const isEdit = installationId ? true : false;
   const navigate = useNavigate();

   useEffect(() => {
      if (installationId || searchParams.get('installationId')) {
         loadStepData();
      }
      loadPageLang(pageLang, getLangPage, setPageLang);
   }, [activeStep]);

   const update = async (data: Partial<InstallationFormData>): Promise<any> => {
      const payload = configurePayload(data, activeStep);
      const installationIdFromSearchParams = searchParams.get('installationId');
      const installationIdToUse = installationId || installationIdFromSearchParams;

      let response;
      // Create Installation - Initial step
      if (isFirstStep && !isEdit && !installationIdFromSearchParams) {
         response = await axios.post<{
            data: InstallationResponse;
            message: string;
         }>(route(API_ENDPOINTS.REGISTRATION.INSTALLATION.STORE), payload);
         // Patch on every other step
      } else if (installationIdToUse && activeStep) {
         response = await axios.patch<{
            data: InstallationResponse;
            message: string;
         }>(
            route(API_ENDPOINTS.REGISTRATION.INSTALLATION.UPDATE, {
               installationId: installationIdToUse || '',
               step: String(activeStep),
            }),
            payload
         );
      } else {
         throw new Error('Unable to submit. Missing required data');
      }

      // Item created. happens at step 1 only
      if (response?.status === 201) {
         const { data } = response;
         nextStep({
            installationId: data?.data?.id,
         });
         // every other call
      } else if (response?.status === 200) {
         const { data } = response;
         if (isLastStep) {
            setDisabled(false);
            navigate(`/installation/${data?.data?.id}`);
         } else {
            nextStep();
         }
      }
   };

   const form = useForm(update, defaultData, { silentSuccess: true });
   const formRef = useScrollToError(form.errors);
   const { Submit, setValues, store } = form;

   const handleBack = () => {
      prevStep();
   };

   const title = useMemo(
      () =>
         isEdit
            ? replaceKeyWithValue(
                 crud?.pages?.edit.title || 'Edit :model',
                 'model',
                 crud?.models?.installation || 'Installation'
              )
            : replaceKeyWithValue(
                 crud?.pages?.create.title || 'Create :model',
                 'model',
                 crud?.models?.installation || 'Installation'
              ),
      [crud, isEdit]
   );

   const loadStepData = async () => {
      setLoading(true);
      setDisabled(true);

      try {
         const installationIdToUse = installationId || searchParams.get('installationId') || '';

         const [stepData, step2Data] = await Promise.all([
            axios.get<{
               data: Partial<InstallationFormData>;
               message: string;
            }>(
               route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
                  installationId: installationIdToUse,
                  step: String(activeStep),
               })
            ),

            axios.get<{
               data: Partial<InstallationFormData>;
               message: string;
            }>(
               route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
                  installationId: installationIdToUse,
                  step: String(STEPS.STEP_2),
               })
            ),
         ]);

         const appliances = stepData?.data?.data?.appliances?.length
            ? stepData.data.data.appliances
            : [defaultApplianceItem];

         setValues({
            ...store,
            ...stepData.data.data,
            appliances,
            system_type: step2Data.data.data.system_type,
         });
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message,
            variant: Variants.Danger,
         });
      } finally {
         setLoading(false);
         setDisabled(false);
      }
   };

   const btnLabel = useMemo(() => {
      const stepType = isLastStep ? 'confirm' : 'next';

      const defaultLabel = crud?.buttons?.[stepType]?.default || (isLastStep ? 'Confirm' : 'Next');

      const loadingLabel =
         crud?.buttons?.[stepType]?.submitting || (isLastStep ? 'Confirming...' : 'Saving...');

      return { loadingLabel, defaultLabel };
   }, [isLastStep, crud]);

   return (
      <PageLayout
         title={StringHelpers.title(title)}
         progressBar={{ activeStep, totalSteps: TOTAL_STEPS }}
      >
         <WidthConstrainedContainer>
            <Card style={{ maxWidth: '1440px' }}>
               <CardBody>
                  <div ref={formRef}>
                     <Stepper
                        title={StringHelpers.upper(
                           `${crud?.sub_titles?.step || 'step'} ${activeStep}/${TOTAL_STEPS}`
                        )}
                        activeStep={activeStep}
                        rightSlot={
                           <>
                              <div
                                 onClick={() => setHelpModalOpen(true)}
                                 role="button"
                                 className="pe d-flex align-items-center gap-1 text-primary"
                              >
                                 <span className="fw-semibold" style={{ fontSize: '17px' }}>
                                    {pageLang?.page?.help_modal?.title}
                                 </span>
                                 <Icon icon={'help'} />
                              </div>
                              <Modal
                                 open={helpModalOpen}
                                 onClose={() => setHelpModalOpen(false)}
                                 title={pageLang?.page?.help_modal?.title || ''}
                              >
                                 <p>{pageLang?.page?.help_modal?.description}</p>
                              </Modal>
                           </>
                        }
                     >
                        {[Step1, Step2, Step3, Step4, Step5, Step6, Step7].map(
                           (StepComponent, index) => (
                              <StepComponent
                                 key={index}
                                 form={form}
                                 loading={loading}
                                 stepLang={{
                                    ...pageLang?.page?.steps?.[index],
                                    helper_texts: pageLang?.page?.helper_texts,
                                    generic: pageLang?.generic,
                                 }}
                                 pageLang={pageLang}
                              />
                           )
                        )}
                     </Stepper>
                  </div>
               </CardBody>
            </Card>
         </WidthConstrainedContainer>
         <BottomAnchoredControls>
            <div className="me-auto ms-5">
               <Button
                  label={StringHelpers.title(crud?.buttons?.exit?.default || 'Exit')}
                  onClick={() => {
                     window.confirm(
                        crud?.modals?.alert?.description?.at(0) || DEFAULT_NAVIGATION_GUARD
                     ) && navigate('/installation');
                  }}
                  variant={Variants.Dark}
                  type={ButtonTypes.Outline}
                  disabled={disabled}
               />
            </div>
            {!isFirstStep && (
               <Button
                  label={crud?.buttons?.previous?.default || 'Previous'}
                  onClick={handleBack}
                  variant={Variants.Dark}
                  type={ButtonTypes.Outline}
                  className={'me-4'}
                  disabled={disabled}
               />
            )}
            <Submit
               disabled={disabled}
               defaultLabel={btnLabel.defaultLabel}
               loadingLabel={btnLabel.loadingLabel}
            />
         </BottomAnchoredControls>
      </PageLayout>
   );
}

export default Journey;
