import axios from 'axios';
import { useEffect, useState } from 'react';
import { WarrantyEventLogType } from '../../../types/WarrantyEventLogType';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import {
    route,
    API_ENDPOINTS,
    WidthConstrainedContainer,
    Spinner,
    Card,
    CardBody,
    SubTitle,
    StringHelpers,
    useLangContext,
    DateFormats,
    customToast,
    Variants,
    CardData
} from 'carrier-fe';

function WarrantiesEventLog({ warrantyId }: { warrantyId: string }) {
    const { fields, responses, crud } = useLangContext();
    const [eventLogs, setEventLogs] = useState<WarrantyEventLogType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    route(API_ENDPOINTS.REGISTRATION.WARRANTY.EVENT_LOG, {
                        warrantyId
                    })
                );
                setEventLogs(response.data.data || []);
            } catch (err: any) {
                customToast({
                    title:
                        err?.response?.data?.message ||
                        '',
                    variant: Variants.Danger,
                })
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [warrantyId]);

    return (
        <div>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <Spinner />
                </div>
            ) : (
                <>
                    <strong className="mb-3 mt-2 w-100">{StringHelpers.title(crud?.models?.warranty + ' ' + responses?.models?.event_log || '')}</strong>
                    <div className="table-responsive mt-3">
                        {eventLogs.length > 0 ?
                            <table className="table w-100">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 px-4 py-2">{StringHelpers.title(fields?.event_at || 'Date')}</th>
                                        <th className="border border-gray-300 px-4 py-2">{StringHelpers.title(fields?.event_name || 'Event')}</th>
                                        <th className="border border-gray-300 px-4 py-2">{StringHelpers.title(fields?.user_name || 'User')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(
                                        eventLogs.map((log) => (
                                            <tr key={log.model_id}>
                                                <td className="border border-gray-300 px-4 py-2">
                                                    {format(new Date(log.created_at), DateFormats.DATE)}
                                                </td>
                                                <td className="border border-gray-300 px-4 py-2">
                                                    {log.display_event_name || '-'}
                                                </td>
                                                <td className="border border-gray-300 px-4 py-2">
                                                    {log.display_causer || '-'}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table> : (
                                <p >
                                    {StringHelpers.replace(responses?.errors?.no_results_found, ':attribute', responses?.models?.event_log) || 'No Event logs found'}
                                </p>

                            )}
                    </div>
                </>
            )}
        </div>
    );
}

export default WarrantiesEventLog;
