import { Dispatch, SetStateAction } from 'react';
import { InstallationFormData } from '../pages/installation/journey';
import { MERCHANT_IDS, STEPS, SYSTEM_TYPES } from './Constants';

type FormDataKeys = keyof InstallationFormData;

/**
 * Configures Installation journey payload based on step
 * @param data Form data
 * @param activeStep 1,2,3,4...etc
 * @returns Partial of InstallationFormData
 */
export const configurePayload = (
   data: Partial<InstallationFormData>,
   activeStep: number
): Partial<InstallationFormData> => {
   // Utility to delete fields from an object conditionally
   const deleteFields = (obj: Partial<InstallationFormData>, fields: FormDataKeys[]) => {
      fields.forEach((field) => delete obj[field as FormDataKeys]);
   };

   const stepsPayloads: Record<number, Partial<InstallationFormData>> = {
      [STEPS.STEP_1]: {
         installer_company_id: data.installer_company_id,
         installer_user_id: data.installer_user_id,
         gas_safe_licence_card_number: data.gas_safe_licence_card_number,
      },
      [STEPS.STEP_2]: {
         system_type: data.system_type,
      },
      [STEPS.STEP_3]: {
         completion_date: data.completion_date,
         subcontractor_exists: data.subcontractor_exists,
         subcontractor_company_gas_safe_number: data.subcontractor_company_gas_safe_number,
         subcontractor_engineer_gas_safe_licence_card_number:
            data.subcontractor_engineer_gas_safe_licence_card_number,
         subcontractor_company_gas_safe_number_confirmed:
            data.subcontractor_company_gas_safe_number_confirmed,
         subcontractor_engineer_gas_safe_licence_card_number_confirmed:
            data.subcontractor_engineer_gas_safe_licence_card_number_confirmed,
         subcontractor_address_id: data.subcontractor_address_id,
         subcontractor_local_authority_code: data.subcontractor_local_authority_code,
         subcontractor_address_line_1: data.subcontractor_address_line_1,
         subcontractor_address_line_2: data.subcontractor_address_line_2,
         subcontractor_address_line_3: data.subcontractor_address_line_3,
         subcontractor_town_city: data.subcontractor_town_city,
         subcontractor_state_county: data.subcontractor_state_county,
         subcontractor_postcode_zipcode: data.subcontractor_postcode_zipcode,
         subcontractor_country_code_iso_3: data.subcontractor_country_code_iso_3,
         subcontractor_company_name: data.subcontractor_company_name,
         subcontractor_engineer_first_name: data.subcontractor_engineer_first_name,
         subcontractor_engineer_last_name: data.subcontractor_engineer_last_name,
         subcontractor_sub_country_name: data.subcontractor_sub_country_name,
      },
      [STEPS.STEP_4]: {
         address_id: data?.address_id,
         address_line_1: data?.address_line_1,
         address_line_2: data?.address_line_2,
         address_line_3: data?.address_line_3,
         town_city: data?.town_city,
         state_county: data?.state_county,
         postcode_zipcode: data?.postcode_zipcode,
         local_authority_code: data?.local_authority_code,
         sub_country_name: data?.sub_country_name,
         country_code_iso_3: data?.country_code_iso_3,
         location_new_build: data?.location_new_build,
         job_reference: data?.job_reference,
         customer_property_type_for_gas_safe: data?.customer_property_type_for_gas_safe,
      },
      [STEPS.STEP_5]: {
         customer_contact_type_for_gas_safe: data?.customer_contact_type_for_gas_safe,
         customer_title: data?.customer_title,
         first_name: data?.first_name,
         last_name: data?.last_name,
         email: data?.email,
         landline_phone_prefix: data?.landline_phone_prefix,
         landline_phone_number: data?.landline_phone_number,
         mobile_phone_prefix: data?.mobile_phone_prefix,
         mobile_phone_number: data?.mobile_phone_number,
      },
      [STEPS.STEP_6]: {
         appliances: data.appliances?.map((appliance) => {
            const applianceData = { ...appliance } as Partial<
               InstallationFormData['appliances'][number]
            >;
            if (data.system_type !== SYSTEM_TYPES.GAS_BOILER) {
               delete applianceData.gas_safe_product_location;
               delete applianceData.gas_safe_work_category_id;
            }
            if (appliance.merchant_id !== MERCHANT_IDS.OTHER) {
               delete applianceData.other_merchant_name;
            }
            if (appliance?.installer_will_service) {
               delete applianceData.viessmann_will_contact_customer_about_servicing;
               delete applianceData.servicing_details;
            }
            if(!appliance?.notify_building_regulations){
               delete applianceData.sub_work_cats;
            }
            delete applianceData.appliance_details;
            return applianceData as InstallationFormData['appliances'][number];
         }),
      },
      [STEPS.STEP_7]: {
         confirmed: data.confirmed,
      },
   };
   

   if (!data.subcontractor_exists) {
      const subcontractorFields: FormDataKeys[] = [
         'subcontractor_address_id',
         'subcontractor_address_line_1',
         'subcontractor_address_line_2',
         'subcontractor_address_line_3',
         'subcontractor_sub_country_name',
         'subcontractor_local_authority_code',
         'subcontractor_town_city',
         'subcontractor_state_county',
         'subcontractor_postcode_zipcode',
         'subcontractor_country_code_iso_3',
         'subcontractor_company_name',
         'subcontractor_company_gas_safe_number_confirmed',
         'subcontractor_company_gas_safe_number',
         'subcontractor_engineer_first_name',
         'subcontractor_engineer_last_name',
         'subcontractor_engineer_gas_safe_licence_card_number_confirmed',
         'subcontractor_engineer_gas_safe_licence_card_number',
      ];
      deleteFields(stepsPayloads[STEPS.STEP_3] || {}, subcontractorFields);
   }

   if (data.system_type !== SYSTEM_TYPES.GAS_BOILER) {
      const gasBoilerFields: FormDataKeys[] = [
         'customer_property_type_for_gas_safe',
         'subcontractor_company_gas_safe_number',
         'subcontractor_engineer_gas_safe_licence_card_number',
         'subcontractor_company_gas_safe_number_confirmed',
         'subcontractor_engineer_gas_safe_licence_card_number_confirmed',
      ];
      deleteFields(stepsPayloads[STEPS.STEP_4] || {}, gasBoilerFields);
      deleteFields(stepsPayloads[STEPS.STEP_3] || {}, gasBoilerFields);
   }

   return stepsPayloads[activeStep];
};

export const loadPageLang = async (
   pageLang: any,
   getLangPage: Function,
   setPageLang: Dispatch<SetStateAction<any>>
) => {
   if (pageLang) return;
   try {
      const response = await getLangPage('registration/viessmann-installation/create-edit');
      setPageLang(response);
   } catch (error: any) {
      console.error(error);
   }
};
