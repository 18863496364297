import {
   Alert,
   API_ENDPOINTS,
   customToast,
   FormInput,
   GasSafeEngineerResponse,
   GasSafeLookup,
   LinkifyText,
   replaceKeyWithValue,
   Roles,
   route,
   SelectOption,
   Stepper,
   StringHelpers,
   useDisabledContext,
   useLangContext,
   useQueries,
   useUserContext,
   Variants,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { StepProps } from '../../types/Journey';
import { QUERY_KEYS, SYSTEM_TYPES } from '../../util/Constants';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

interface InstallerOptions extends SelectOption {
   gas_safe_licence_card_number: string;
   system_types_status: {
      [key in SYSTEM_TYPES]: boolean | string;
   };
}

function Step1({ form, loading, stepLang }: StepProps) {
   const [searchParams] = useSearchParams();
   const systemTypeFromParams = searchParams.get('type');
   const { setValues, store } = form;
   const { crud, fields } = useLangContext();
   const { disabled } = useDisabledContext();
   const [isFetching, setIsFetching] = useState<boolean>(false);
   const [gasSafeNameMismatch, setGasSafeNameMismatch] = useState<boolean>(false);
   const [gasSafeLookupData, setGasSafeLookupData] = useState<GasSafeEngineerResponse | undefined>(
      undefined
   );
   const [formError, setFormError] = useState<string[] | undefined>(undefined);

   const [selects, setSelects] = useState<{ [key: string]: InstallerOptions[] }>({
      installer_companies: [],
      installer_users: [],
   });
   const { user } = useUserContext();

   const { data, error, isLoading } = useQueries([
      {
         key: QUERY_KEYS.INSTALLER_COMPANIES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.COMPANIES}?types[]=installer`),
         },
      },
   ]);

   useEffect(() => {
      // Handle API data and error
      if (data) {
         setSelects({
            ...selects,
            installer_companies: data.installer_companies?.data ?? [],
         });
      }

      if (error) {
         Object.values(error).map((e) =>
            customToast({ title: (e?.response?.data as any)?.message, variant: Variants.Danger })
         );
      }

      // Set default values if the user is an engineer
      if (user?.id && user?.role_name === Roles.ENGINEER) {
         setValues({
            installer_user_id: user.id,
            installer_company_id: user?.individual?.company_id,
         });
      } else if (user?.id && user?.role_name === Roles.COMPANY_MANAGER) {
         setValues({
            installer_company_id: user?.individual?.company_id,
         });
      }
   }, [data, error, user]);

   useEffect(() => {
      if (store?.installer_company_id) {
         fetchInstallers();
      }
   }, [store?.installer_company_id]);

   const fetchInstallers = async () => {
      setIsFetching(true);
      try {
         const response = await axios.get(
            route(API_ENDPOINTS.GENERIC.SELECT.INSTALLERS, {
               companyId: store?.installer_company_id,
            })
         );
         setSelects((prev) => ({
            ...prev,
            installer_users: response?.data?.data ?? [],
         }));
         setIsFetching(false);
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || '',
            variant: Variants.Danger,
         });
      } finally {
         setIsFetching(false);
      }
   };

   const disableCompanyField = useMemo(
      () =>
         (user?.role_name === Roles.COMPANY_MANAGER || user?.role_name === Roles.ENGINEER) &&
         !!store?.installer_company_id,
      [store, user?.role_name]
   );
   const disableEngineerField = useMemo(
      () =>
         !store?.installer_company_id ||
         (user?.role_name === Roles.ENGINEER && !!store?.installer_user_id),
      [store, user?.role_name]
   );

   const disclaimers = useMemo(() => {
      const messages = { engineer: '', company: '' };
      if (user?.role_name === Roles.COMPANY_MANAGER) {
         messages.company = stepLang?.helper_texts?.installer_company || '';
      }
      if (user?.role_name === Roles.ENGINEER) {
         messages.company = stepLang?.helper_texts?.installer_company_individual || '';
         messages.engineer = stepLang?.helper_texts?.installer_individual || '';
      }

      return messages;
   }, [stepLang, user?.role_name]);

   // Show lookup if installer is selected and has no gas safe number and system type is gas boiler
   const showGasSafeLookup = useMemo(() => {
      const installerOption = selects.installer_users.find(
         (user) => user.value === form?.store?.installer_user_id
      );

      if (typeof installerOption?.system_types_status?.gas_boiler === 'string') {
         const gasBoilerError = installerOption?.system_types_status?.gas_boiler || '';
         const errs = systemTypeFromParams
            ? [gasBoilerError]
            : Object.values(installerOption?.system_types_status)?.filter(
                 (val) => typeof val === 'string'
              );
         setFormError(errs as any);
      }

      return (
         !!form?.store?.installer_user_id &&
         !installerOption?.gas_safe_licence_card_number &&
         (!systemTypeFromParams || systemTypeFromParams === SYSTEM_TYPES.GAS_BOILER)
      );
   }, [form?.store?.installer_user_id, selects.installer_users, systemTypeFromParams]);

   const checkNameMismatch = (lookupData: GasSafeEngineerResponse) => {
      const selectedEngineer = selects.installer_users.find(
         (user) => user.value === form?.store?.installer_user_id
      );
      if (selectedEngineer) {
         return (
            selectedEngineer.label?.toLocaleLowerCase() !==
            `${lookupData?.first_name} ${lookupData?.last_name}`.toLocaleLowerCase()
         );
      }
      return false;
   };

   const myAccountPageLink = useMemo(() => {
      const dashbaordUrl = process.env.REACT_APP_DASHBOARD_URL || '';
      return `${dashbaordUrl}/my-account`;
   }, []);

   const nameMismatchText = useMemo(() => {
      return (
         stepLang?.helper_texts?.gassafe_licence_card_will_be_compared ||
         'The details on the Gas Safe licence card will be checked against the registration details we have on record. Please make sure that the name in your VPlus account is identical to the name on your Gas Safe licence card. If your name does not match, please update it here :link .'
      );
   }, [stepLang]);

   return (
      <Stepper.Step
         loading={isLoading || loading}
         title={stepLang?.title}
         description={stepLang?.description}
      >
         <div className="d-flex flex-column gap-4">
            <div>
               <FormInput
                  name={'installer_company_id'}
                  type="searchable-select"
                  label={StringHelpers.title(fields?.installer_company_id ?? 'Company')}
                  disabled={disableCompanyField || disabled}
                  options={selects.installer_companies}
                  value={form?.store?.installer_company_id}
                  onChange={(val: any) =>
                     form?.setValues({
                        installer_company_id: val,
                     })
                  }
                  noMargin
                  errorMessages={form?.errors?.installer_company_id}
                  description={disableCompanyField ? disclaimers.company : ''}
               />
            </div>
            <div>
               <FormInput
                  name={'installer_user_id'}
                  type="searchable-select"
                  placeholder={
                     isFetching
                        ? crud?.buttons?.find?.submitting
                        : replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                  }
                  loading={isFetching}
                  label={StringHelpers.title(fields?.installer_user_id ?? 'Engineer')}
                  disabled={isFetching || disableEngineerField || disabled}
                  options={selects.installer_users}
                  value={form?.store?.installer_user_id}
                  onChange={(val: any) => {
                     setFormError(undefined);
                     form?.setValues({
                        installer_user_id: val,
                     });
                  }}
                  noMargin
                  errorMessages={form?.errors?.installer_user_id}
                  description={disableEngineerField ? disclaimers.engineer : ''}
               />
            </div>
            {formError && (
               <Alert variant={Variants.Warning}>
                  {formError?.length > 1 ? formError.map((err, index) => (
                     <li key={index}>{err}</li>
                  )) : formError[0]}
               </Alert>
            )}
            {showGasSafeLookup && (
               <div>
                  <GasSafeLookup
                     displayCard
                     type={'engineer'}
                     onGasSafeNumberChange={(value) =>
                        form?.setValues({
                           gas_safe_licence_card_number: value,
                        })
                     }
                     hideConfirmed
                     disabled={disabled}
                     gasSafeNumber={form?.store?.gas_safe_licence_card_number}
                     errors={{
                        gasSafeNumber: form?.errors?.gas_safe_licence_card_number,
                     }}
                     labels={{
                        gasSafeNumber: fields?.gas_safe_licence_card_number,
                     }}
                     onGasSafeLookupChange={(lookupData) => {
                        if (!lookupData?.engineer) return;
                        setGasSafeLookupData(lookupData?.engineer);
                        const nameMismatch = checkNameMismatch(lookupData?.engineer);
                        setGasSafeNameMismatch(nameMismatch);
                     }}
                     content={
                        <>
                           {gasSafeNameMismatch && gasSafeLookupData && (
                              <Alert variant={Variants.Warning}>
                                 <LinkifyText
                                    text={replaceKeyWithValue(
                                       nameMismatchText,
                                       'link',
                                       myAccountPageLink
                                    )}
                                 />
                              </Alert>
                           )}
                        </>
                     }
                  />
               </div>
            )}
         </div>
      </Stepper.Step>
   );
}

export default Step1;
