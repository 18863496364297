import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	FormError,
	FormInput,
	ImageType,
	Permissions,
	PostcodeLookup,
	replaceKeyWithValue,
	route,
	SelectOption,
	Stepper,
	StringHelpers,
	SubTitle,
	useAuthorisationContext,
	useDisabledContext,
	useLangContext,
	useQueries,
	Variants,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StepProps } from '../../types/Journey';
import { QUERY_KEYS, SYSTEM_TYPES, TEN_MINUTES } from '../../util/Constants';

const cacheConfig = {
	enabled: true,
	config: {
		expires: TEN_MINUTES,
	},
};

function Step4({ form, stepLang }: StepProps) {
	const [searchParams] = useSearchParams();
	const urlParams = useParams();
	const { crud, fields } = useLangContext();

	const [showGasSafeFields, setShowGasSafeFields] = useState(
		form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER
	);

	const installationId =
		searchParams.get('installationId') || urlParams?.installationId;

	const [selects, setSelects] = useState<{ [key: string]: SelectOption[] }>({
		property_types: [],
	});

	const { data, error, isLoading, refetch, refetching } = useQueries([
		{
			key: QUERY_KEYS.PROPERTY_TYPES,
			config: {
				method: 'GET',
				url: route(`${API_ENDPOINTS.GENERIC.GAS_SAFE.PROPERTY_TYPES}`),
			},
			cache: cacheConfig,
		},
		{
			key: 'step_2',
			config: {
				method: 'GET',
				url: route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
					installationId: installationId || '',
					step: '2',
				}),
			},
		},
	]);

	useEffect(() => {
		if (data) {
			setSelects((prev) => ({
				...prev,
				property_types: data?.property_types?.data ?? [],
			}));

			if (data?.step_2?.data) {
				form?.setValues({
					system_type: data?.step_2?.data?.system_type,
				});
				setShowGasSafeFields(
					data?.step_2?.data?.system_type === SYSTEM_TYPES.GAS_BOILER
				);
			}
		}
	}, [data]);

	return (
		<Stepper.Step
			loading={isLoading}
			title={stepLang?.title}
			description={stepLang?.description}
		>
			<div className="d-flex flex-column gap-2">
				<div className="mb-3">
					<PostcodeLookup
						data={form?.store}
						setData={form?.setValues}
						errors={form?.errors}
					/>
				</div>
				<FormInput
					name="location_new_build"
					label={StringHelpers.title(
						fields?.location_new_build || ''
					)}
					type="select"
					required
					value={form.store['location_new_build']}
					onChange={(
						value: string | boolean | string[] | ImageType | null
					) =>
						form?.setValues({
							location_new_build:
								typeof value === 'string'
									? JSON.parse(value)
									: value,
						})
					}
					options={crud?.options?.boolean.map(
						(option: SelectOption) => ({
							...option,
							value: Number(option.value) === 1 ? true : false,
						})
					)}
					errorMessages={form.errors?.['location_new_build']}
				/>
				{form.store.location_new_build &&
					form.Input({
						name: 'local_authority_code',
						label: StringHelpers.title(
							fields?.local_authority_code ||
								'Local authority code'
						),
						type: 'text',
					})}
				{form.Input({
					name: 'customer_property_type_for_gas_safe',
					label: StringHelpers.title(
						fields?.customer_property_type_for_gas_safe ||
							'Property Type'
					),
					placeholder: StringHelpers.title(
						replaceKeyWithValue(
							crud?.placeholders?.select || 'Select :model',
							'model',
							fields?.customer_property_type_for_gas_safe ||
								'Property Type'
						)
					),
					type: 'select',
					options: selects.property_types,
					visible: showGasSafeFields,
				})}
				{form.Input({
					name: 'job_reference',
					label: StringHelpers.title(
						fields?.job_reference || 'Job Reference'
					),
					type: 'text',
				})}
			</div>
		</Stepper.Step>
	);
}

export default Step4;
