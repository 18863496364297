import {
	API_ENDPOINTS,
	customToast,
	route,
	FormInput,
	ImageType,
	Modal,
	StringHelpers,
	useLangContext,
	Variants,
	useDisabledContext,
} from 'carrier-fe';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { InstallationType } from '../types/installationType';
import { useParams } from 'react-router-dom';

export type ConfirmationModalTypes =
	| 'gasSafe'
	| 'warranty'
	| 'warrantySelection';

function ConfirmationModal({
	modalOpen,
	modalClose,
	title,
	dynamicText,
	defaultEmail,
	type,
	onSuccess,
	storeData,
}: {
	onSuccess?: () => void;
	modalOpen: boolean;
	modalClose: () => void;
	title: string;
	dynamicText: string;
	disabled?: boolean;
	defaultEmail: string;
	type: ConfirmationModalTypes;
	storeData: InstallationType;
	emailEnabled?: boolean;
}) {
	const { crud, fields } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();
	const { installationId } = useParams();

	const [isSending, setIsSending] = useState<boolean>(false);
	const [email, setEmail] = useState<string>(defaultEmail);

	const handleEmailChange = (
		value: string | boolean | ImageType | string[] | null
	) => {
		if (typeof value === 'string') {
			setEmail(value);
		}
	};

	const handleSendEmail = async (confirmSend: boolean) => {
		if (!confirmSend) return;

		try {
			setIsSending(true);
			setDisabled(true);

			const emailToUse =
				email.trim() ||
				storeData?.customer_company?.primary_individual?.email;
			let endpoint = '';

			if (type === 'gasSafe') {
				endpoint =
					API_ENDPOINTS.REGISTRATION.INSTALLATION.SEND_GAS_SAFE_EMAIL;
			} else if (type === 'warranty') {
				endpoint =
					API_ENDPOINTS.REGISTRATION.INSTALLATION.WARRANTY_RESEND;
			} else if (type === 'warrantySelection') {
				endpoint =
					API_ENDPOINTS.REGISTRATION.INSTALLATION
						.WARRANTY_OPTIONS_RESEND;
			}

			const response = await axios.patch(
				route(endpoint, {
					installationId: String(installationId),
				}),
				{
					email: emailToUse,
				}
			);

			customToast({
				title: response?.data?.message || '',
				variant: Variants.Success,
			});

			onSuccess?.();
			modalClose();
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		} finally {
			setIsSending(false);
			setEmail('');
			setDisabled(false);
		}
	};

	return (
		<Modal
			open={modalOpen}
			onClose={modalClose}
			title={StringHelpers.title(title)}
			confirmText={
				isSending
					? crud?.buttons?.send?.submitting || 'Sending...'
					: crud?.buttons?.send?.default || 'Send'
			}
			closeText={crud?.buttons.cancel?.default || 'Cancel'}
			onConfirm={() => handleSendEmail(true)}
			disabled={disabled || isSending}
			variant={Variants.Danger}
		>
			<p>{dynamicText}</p>

			<FormInput
				type="text"
				label={StringHelpers.title(
					fields?.customer_email_override_exists ||
						'Override customer email'
				)}
				name="new_email"
				value={email}
				onChange={handleEmailChange}
			/>
		</Modal>
	);
}

export default ConfirmationModal;
