import axios from 'axios';
import {
   API_ENDPOINTS,
   Button,
   FormError,
   FormInput,
   ImageType,
   Permissions,
   PhoneNumberField,
   replaceKeyWithValue,
   route,
   SelectOption,
   Stepper,
   StringHelpers,
   SubTitle,
   useAuthorisationContext,
   useDisabledContext,
   useLangContext,
   useQueries,
   Variants,
} from 'carrier-fe';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StepProps } from '../../types/Journey';
import { QUERY_KEYS, SYSTEM_TYPES, TEN_MINUTES } from '../../util/Constants';

const cacheConfig = {
   enabled: true,
   config: {
      expires: TEN_MINUTES,
   },
};

function Step5({ form, stepLang }: StepProps) {
   const [searchParams] = useSearchParams();
   const urlParams = useParams();
   const { hasPermissionAccess, hasRoleAccess } = useAuthorisationContext();
   const { disabled } = useDisabledContext();
   const installationId = searchParams.get('installationId') || urlParams?.installationId;
   const [showGasSafeFields, setShowGasSafeFields] = useState(
      form?.store?.system_type === SYSTEM_TYPES.GAS_BOILER
   );
   const { crud, fields } = useLangContext();
   const [selects, setSelects] = useState<{ [key: string]: SelectOption[] }>({
      contact_types: [],
      contact_titles: [],
   });
   const [customer, setCustomers] = useState<any | null>();

   const { data, error, isLoading, refetch, refetching } = useQueries([
      {
         key: QUERY_KEYS.CONTACT_TYPES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.GAS_SAFE.CONTACT_TYPES}`),
         },
         cache: cacheConfig,
      },
      {
         key: QUERY_KEYS.CONTACT_TITLES,
         config: {
            method: 'GET',
            url: route(`${API_ENDPOINTS.GENERIC.SELECT.TITLES}`),
         },
         cache: cacheConfig,
      },
      {
         key: 'step_2',
         config: {
            method: 'GET',
            url: route(API_ENDPOINTS.REGISTRATION.INSTALLATION.EDIT, {
               installationId: installationId || '',
               step: '2',
            }),
         },
      },
   ]);

   useEffect(() => {
      if (data) {
         setSelects((prev) => ({
            ...prev,
            contact_types: data?.contact_types?.data ?? [],
            contact_titles: data?.contact_titles?.data ?? [],
         }));

         if (data?.step_2?.data) {
            form?.setValues({
               system_type: data?.step_2?.data?.system_type,
            });
            setShowGasSafeFields(data?.step_2?.data?.system_type === SYSTEM_TYPES.GAS_BOILER);
         }
      }
   }, [data]);

   const yesNoOptions = useMemo(
      () =>
         crud?.options?.boolean?.map((option: SelectOption) => ({
            ...option,
            value: Number(option.value) === 1 ? true : false,
         })),
      [crud?.options?.boolean]
   );

   return (
      <Stepper.Step loading={isLoading} title={stepLang?.title} description={stepLang?.description}>
         <div className="d-flex flex-column gap-2">
            <SubTitle
               title={crud?.subtitle?.details || 'Details'}
               className="mb-3 w-100"
               style={{ color: '#464C5E' }}
            />

            <div className="row">
               <div className="col-12 col-md-6">
                  {form.Input({
                     name: 'customer_title',
                     placeholder: StringHelpers.title(
                        replaceKeyWithValue(
                           crud?.placeholders?.select || 'Select :model',
                           'model',
                           ''
                        )
                     ),
                     label: StringHelpers.title(fields?.customer_title || 'Contact Title'),
                     type: 'select',
                     options: selects?.contact_titles,
                  })}
               </div>
               <div className="col-12 col-md-6">
                  {form.Input({
                     name: 'customer_contact_type_for_gas_safe',
                     placeholder: StringHelpers.title(
                        replaceKeyWithValue(
                           crud?.placeholders?.select || 'Select :model',
                           'model',
                           ''
                        )
                     ),
                     label: StringHelpers.title(fields?.customer_contact_type_for_gas_safe || ''),
                     type: 'select',
                     options: selects?.contact_types,
                  })}
               </div>
            </div>
            <>
               <div className="row">
                  <div className="col-12 col-md-6">
                     {form.Input({
                        name: 'first_name',
                        label: StringHelpers.title(fields?.first_name || 'First Name'),
                        type: 'text',
                     })}
                  </div>
                  <div className="col-12 col-md-6">
                     {form.Input({
                        name: 'last_name',
                        label: StringHelpers.title(fields?.last_name || 'Last Name'),
                        type: 'text',
                     })}
                  </div>
                  <div className="col-12">
                     {form.Input({
                        name: 'email',
                        label: StringHelpers.title(fields?.email || 'Email'),
                        type: 'email',
                     })}
                  </div>
                  <div className="col-12 mb-3">
                     <PhoneNumberField
                        noMargin
                        label={StringHelpers.title(fields.mobile_phone_number || 'Mobile number')}
                        name="mobile_phone_number"
                        value={form?.store?.mobile_phone_number ?? ''}
                        onChange={(value) =>
                           form.setValues({
                              mobile_phone_number: value,
                           })
                        }
                        disabled={disabled}
                        errorMessages={form?.errors?.mobile_phone_number}
                        prefixValue={form?.store?.mobile_phone_prefix ?? ''}
                        prefixOnChange={(value) =>
                           form.setValues({
                              mobile_phone_prefix: value,
                           })
                        }
                     />
                  </div>

                  {/* Landline Details */}
                  <div className="col-12 mb-5">
                     <PhoneNumberField
                        noMargin
                        label={StringHelpers.title(
                           fields.landline_phone_number || 'Landline number'
                        )}
                        name="landline_phone_number"
                        value={form?.store?.landline_phone_number ?? ''}
                        onChange={(value) =>
                           form.setValues({
                              landline_phone_number: value,
                           })
                        }
                        disabled={disabled}
                        errorMessages={form?.errors?.landline_phone_number}
                        prefixValue={form?.store?.landline_phone_prefix ?? ''}
                        prefixOnChange={(value) =>
                           form.setValues({
                              landline_phone_prefix: value,
                           })
                        }
                     />
                  </div>
               </div>
            </>

         </div>
      </Stepper.Step>
   );
}

export default Step5;
